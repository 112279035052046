<template>
    <b-card>
        <Form
            ref="createUser"
            :submit="submit"
        />
    </b-card>
</template>

<script>
import Form from "./partials/Form";
import { mapActions } from "vuex";

export default {
    name: "CreateUser",

    components: {
        Form
    },

    methods: {
        ...mapActions({
            createUser: 'genre/create'
        }),
        submit() {
            const refUser = this.$refs.createUser
            refUser.errMessage = null

            const data = JSON.parse(JSON.stringify(refUser.genre))
            data.meta_keywords = data.meta_keywords.toString()
            this.createUser(data).then(() => {
                this.$router.push({ name: 'genres' })
            }).catch(response => {
                if (response.errors) {
                    refUser.$refs.form.setErrors(response.errors)
                }
                refUser.errMessage = response.message
            })
        }
    }
}
</script>
